import { create } from "zustand";
import custAxios from "../../configs/axiosConfig";
import { notifications } from "@mantine/notifications";
import { errorMessage, successMessage } from "../../services/helpers";
import { devtools } from "zustand/middleware";

interface IAuthStore {
  user: any;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  register: (values: any) => Promise<any>;
  loginFunc: (values: any) => Promise<any>;
  logoutFunc: () => Promise<any>;
  forgotPasswordFunc: (values: any) => Promise<any>;
  resetPasswordFunc: (values: any) => Promise<any>;
  checkAuthStatus: () => void;
}

export const useAuthStore = create<IAuthStore>()(
  devtools(
    (set, get) => {
      // Initialize auth state from localStorage
      // Initialize auth state from localStorage
      const initializeAuthState = () => {
        try {
          const userData = localStorage.getItem("user");
          const token = localStorage.getItem("token");

          if (token && userData) {
            const parsedUserData = JSON.parse(userData);
            return {
              user: parsedUserData,
              isAuthenticated: true,
              token: token,
            };
          }
          return {
            user: null,
            isAuthenticated: false,
            token: null,
          };
        } catch (error) {
          console.error("Error initializing auth state:", error);
          // Clear potentially corrupted data
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          return {
            user: null,
            isAuthenticated: false,
            token: null,
          };
        }
      };

      const initialState = initializeAuthState();

      return {
        user: initialState.user,
        isAuthenticated: initialState.isAuthenticated,
        loading: false,
        error: null,

        checkAuthStatus: () => {
          const state = initializeAuthState();
          set({
            user: state.user,
            isAuthenticated: state.isAuthenticated,
          });
        },

        register: async (values) => {
          try {
            set({ loading: true, error: null });
            const response = await custAxios.post("/auth/register", values);

            set({ loading: false }, undefined, "auth/register");

            // Use Mantine's notifications with a single call
            notifications.show({
              title: "Registro exitoso",
              message: "Su cuenta se ha registrado correctamente",
              color: "green",
              autoClose: 3000,
            });

            return response.data;
          } catch (error: any) {
            const errorMsg =
              error?.response?.data?.message || "Error de registro";

            // Ensure only one notification is shown
            notifications.show({
              title: "Error de registro",
              message: errorMsg,
              color: "red",
              autoClose: 3000,
            });

            set(
              {
                loading: false,
                error: errorMsg,
              },
              undefined,
              "auth/register"
            );

            return { success: false, message: errorMsg };
          }
        },

        loginFunc: async (values) => {
          try {
            set({ loading: true, error: null });
            const response = await custAxios.post("/auth/login", values);

            if (response?.data?.success) {
              const userData = response?.data?.data?.userData;
              const token = response?.data?.data?.token;

              localStorage.setItem("user", JSON.stringify(userData));
              localStorage.setItem("token", token);

              set(
                {
                  user: userData,
                  isAuthenticated: true,
                  loading: false,
                  error: null,
                },
                undefined,
                "auth/login"
              );

              // Single notification for successful login
              notifications.show({
                title: "Inicio de sesión exitoso",
                message: "Bienvenido a Your Way Community",
                color: "green",
                autoClose: 3000,
              });

              return response.data;
            } else {
              throw new Error(
                response?.data?.message || "Inicio de sesión fallido"
              );
            }
          } catch (error: any) {
            const errorMsg =
              error?.response?.data?.message || "Credenciales inválidas";

            // Ensure only one notification is shown
            notifications.show({
              title: "Error de inicio de sesión",
              message: errorMsg,
              color: "red",
              autoClose: 3000,
            });

            set(
              {
                loading: false,
                isAuthenticated: false,
                error: errorMsg,
              },
              undefined,
              "auth/login"
            );

            return { success: false, message: errorMsg };
          }
        },

        logoutFunc: async () => {
          try {
            set({ loading: true });

            await custAxios.get("/auth/logout");

            // Clear local storage
            localStorage.removeItem("user");
            localStorage.removeItem("token");

            set({
              user: null,
              isAuthenticated: false,
              loading: false,
              error: null,
            });

            // Single logout notification
            notifications.show({
              title: "Sesión cerrada",
              message: "Ha cerrado sesión exitosamente",
              color: "green",
              autoClose: 3000,
            });

            return { success: true };
          } catch (error) {
            console.error("Logout error:", error);

            // Error notification for logout failure
            notifications.show({
              title: "Error al cerrar sesión",
              message: "No se pudo cerrar la sesión. Intente nuevamente.",
              color: "red",
              autoClose: 3000,
            });

            set(
              {
                loading: false,
                error: "Error al cerrar sesión",
              },
              undefined,
              "auth/logout"
            );

            return { success: false };
          }
        },

        forgotPasswordFunc: async (values) => {
          try {
            set({
              loading: true,
              error: null,
            });
            const res = await custAxios.post("/auth/forgotPassword", values);
            if (res?.data?.success) {
              set({
                loading: false,
              });
              successMessage(res?.data?.data);
            }
            return res.data;
          } catch (error: any) {
            const errorMsg =
              error?.response?.data?.message || "Failed to process request";
            set({
              loading: false,
              error: errorMsg,
            });
            errorMessage(errorMsg);
            return { success: false, message: errorMsg };
          }
        },

        resetPasswordFunc: async (values) => {
          try {
            set(
              {
                loading: true,
                error: null,
              },
              undefined,
              "auth/resetPassword"
            );
            const res = await custAxios.put("/auth/resetPassword", values);
            if (res?.data?.success) {
              set(
                {
                  loading: false,
                  error: null,
                },
                undefined,
                "auth/resetPassword"
              );
              successMessage(res?.data?.data);
            }
            return res.data;
          } catch (error: any) {
            const errorMsg =
              error?.response?.data?.message || "Failed to reset password";
            set(
              {
                loading: false,
                error: errorMsg,
              },
              undefined,
              "auth/resetPassword"
            );
            errorMessage(errorMsg);
            return { success: false, message: errorMsg };
          }
        },
      };
    },
    {
      name: "AuthStore",
      store: "authStore",
    }
  )
);
