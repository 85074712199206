import { create } from "zustand";
import custAxios, { attachToken, baseURL } from "../../configs/axiosConfig";
import { successMessage, errorMessage } from "../../services/helpers";
import { notifications } from "@mantine/notifications";
import { devtools } from "zustand/middleware";
// Add test function at the top level
export const testApiConnection = async () => {
  try {
    console.log("Testing API connection to:", baseURL);
    const response = await custAxios.get("/health");
    console.log("API Health Check Response:", response.data);
    return { success: true, data: response.data };
  } catch (error) {
    console.error("API Connection Error:", error);
    return { success: false, error };
  }
};

interface ISendMsg {
  chatId: string | null;
  prompt: string;
}

interface IPayload {
  page: number;
  limit: number;
  date?: Date | null;
  ip?: string;
}

interface ITags {
  keyword: string;
}

interface IChatStore {
  chatId: string | null;
  aiResponse: string;
  loading: boolean;
  addTagLoader: boolean;
  chatCount: number;
  MesssagesCount: number;
  pages: number;
  chats: [];
  tags: [];
  chat: any;
  error: string | null;
  sendChat: (values: ISendMsg) => Promise<any>;
  fetchChats: (values: IPayload) => Promise<any>;
  fetchMessages: (id: string) => Promise<any>;
  addTags: (value?: ITags) => Promise<any>;
  getTags: () => Promise<any>;
  deleteTags: (id: string) => Promise<any>;
  resetChatState: () => void;
}

export const useAiChatStore = create<IChatStore>()(
  devtools(
    (set, get) => ({
      chatId: null,
      chats: [],
      tags: [],
      chat: {},
      loading: false,
      addTagLoader: false,
      aiResponse: "",
      chatCount: 0,
      MesssagesCount: 0,
      pages: 0,
      error: null,

      resetChatState: () => {
        console.log("Resetting chat state");
        set(
          {
            chatId: null,
            aiResponse: "",
            error: null,
          },
          undefined,
          "chat/resetChatState"
        );
      },

      sendChat: async (values) => {
        try {
          console.log("Sending chat message:", values);
          console.log("Current state before chat:", get());

          set(
            {
              loading: true,
              error: null,
            },
            undefined,
            "chat/sendChat"
          );

          // Ensure token is attached for authenticated endpoints
          attachToken();
          console.log("Token attached, making API request");

          const res = await custAxios.post("/chat/add", values);
          console.log("Chat API Response:", res.data);

          if (res?.data?.success) {
            const newState = {
              loading: false,
              chatId: res?.data?.data?.chatId,
              aiResponse: res?.data?.data?.aiResponse,
              error: null,
            };
            console.log("Updating state with:", newState);
            set(newState, undefined, "chat/sendChat");
            return res?.data?.data;
          } else {
            throw new Error(res?.data?.message || "Failed to get AI response");
          }
        } catch (error: any) {
          console.error("Chat Error Details:", {
            error,
            response: error?.response,
            message: error?.message,
          });

          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error communicating with AI";

          notifications.show({
            title: "AI Chat Error",
            message: errorMsg,
            autoClose: 5000,
            color: "red",
          });

          set(
            {
              loading: false,
              error: errorMsg,
            },
            undefined,
            "chat/sendChat"
          );

          console.error("Error in AI chat:", error);
          return { success: false, message: errorMsg };
        }
      },

      fetchChats: async (values) => {
        try {
          console.log("Fetching chats with params:", values);
          set(
            {
              loading: true,
              error: null,
            },
            undefined,
            "chat/fetchChats"
          );

          attachToken();
          const res = await custAxios.get("/chat", { params: values });
          console.log("Fetch chats response:", res.data);

          if (res?.data?.success) {
            const pages = Math.ceil(res.data?.data?.docCount / values.limit);
            const newState = {
              loading: false,
              chats: res?.data?.data?.chats,
              chatCount: res?.data?.data?.docCount,
              pages: pages,
              error: null,
            };
            console.log("Updating chats state:", newState);
            set(newState, undefined, "chat/fetchChats");
            return { success: true, data: res?.data?.data };
          } else {
            throw new Error(res?.data?.message || "Failed to fetch chats");
          }
        } catch (error: any) {
          console.error("Fetch Chats Error:", {
            error,
            response: error?.response,
            message: error?.message,
          });

          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error fetching chats";

          set(
            {
              loading: false,
              error: errorMsg,
            },
            undefined,
            "chat/fetchChats"
          );

          console.error("Error fetching chats:", error);
          return { success: false, message: errorMsg };
        }
      },

      fetchMessages: async (id) => {
        try {
          set(
            {
              loading: true,
              error: null,
            },
            undefined,
            "chat/fetchMessages"
          );

          attachToken();
          const res = await custAxios.get(`/chat/${id}`);

          if (res?.data?.success) {
            set(
              {
                loading: false,
                chat: res?.data?.data?.chat,
                MesssagesCount: res?.data?.data?.messagesCount,
                error: null,
              },
              undefined,
              "chat/fetchMessages"
            );
            return { success: true, data: res?.data?.data };
          } else {
            throw new Error(res?.data?.message || "Failed to fetch messages");
          }
        } catch (error: any) {
          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error fetching messages";

          set(
            {
              loading: false,
              error: errorMsg,
            },
            undefined,
            "chat/fetchMessages"
          );

          console.error("Error fetching messages:", error);
          return { success: false, message: errorMsg };
        }
      },

      addTags: async (value) => {
        try {
          set(
            {
              addTagLoader: true,
              error: null,
            },
            undefined,
            "chat/addTags"
          );

          attachToken();
          const res = await custAxios.post("/chat/tag", value);

          if (res?.data?.success) {
            set({
              addTagLoader: false,
              error: null,
            });
            successMessage("Tag added successfully");
            return { success: true };
          } else {
            throw new Error(res?.data?.message || "Failed to add tag");
          }
        } catch (error: any) {
          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error adding tag";

          set(
            {
              addTagLoader: false,
              error: errorMsg,
            },
            undefined,
            "chat/addTags"
          );

          errorMessage(errorMsg);
          console.error("Error adding tag:", error);
          return { success: false, message: errorMsg };
        }
      },

      getTags: async () => {
        try {
          set(
            {
              loading: true,
              error: null,
            },
            undefined,
            "chat/getTags"
          );

          attachToken();
          const res = await custAxios.get("/chat/tag");

          if (res?.data?.success) {
            set({
              loading: false,
              tags: res?.data?.data?.tags,
              error: null,
            });
            return { success: true, data: res?.data?.data };
          } else {
            throw new Error(res?.data?.message || "Failed to get tags");
          }
        } catch (error: any) {
          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error fetching tags";

          set(
            {
              loading: false,
              error: errorMsg,
            },
            undefined,
            "chat/getTags"
          );

          console.error("Error fetching tags:", error);
          return { success: false, message: errorMsg };
        }
      },

      deleteTags: async (id) => {
        try {
          set(
            {
              loading: true,
              error: null,
            },
            undefined,
            "chat/deleteTags"
          );

          attachToken();
          const res = await custAxios.delete(`/chat/tag/${id}`);

          if (res?.data?.success) {
            set({
              loading: false,
              error: null,
            });
            successMessage("Tag deleted successfully");
            return { success: true };
          } else {
            throw new Error(res?.data?.message || "Failed to delete tag");
          }
        } catch (error: any) {
          const errorMsg =
            error?.response?.data?.message ||
            error.message ||
            "Error deleting tag";

          set(
            {
              loading: false,
              error: errorMsg,
            },
            undefined,
            "chat/deleteTags"
          );

          errorMessage(errorMsg);
          console.error("Error deleting tag:", error);
          return { success: false, message: errorMsg };
        }
      },
    }),
    {
      name: "AiChatStore",
      store: "aiChatStore",
    }
  )
);
