import { create } from "zustand";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../configs/axiosConfig";
import { devtools } from "zustand/middleware";

interface IQuizStore {
  quiz: any;
  quizParticipants: any;
  leaderboard: any;
  singleQuiz: any;
  singleQuizById: any;
  singleQuizByUser: any;
  loading: boolean;
  addQuiz: (values: any) => Promise<any>;
  getQuizs: (values?: any, search?: any) => Promise<any>;
  getSingleQuizs: (values?: any) => Promise<any>;
  getSingleQuizById: (id?: any) => Promise<any>;
  submitSingleQuizById: (id?: any, value?: any) => Promise<any>;
  editSingleQuizById: (id?: any, value?: any) => Promise<any>;
  deleteSingleQuizById: (id?: any) => Promise<any>;
  getSingleQuizParticipantsById: (id?: any) => Promise<any>;
  getSingleQuizByUser: (id?: any, userid?: any) => Promise<any>;
  checkSingleQuiz: (id: any, values: any) => Promise<any>;
  getSingleQuizLeaderboard: (id: any) => Promise<any>;
  //   loginFunc: (values: any) => Promise<any>;
}

export const useQuizStore = create<IQuizStore>()(
  devtools(
    (set) => ({
      quiz: [],
      quizParticipants: [],
      leaderboard: [],
      singleQuiz: {},
      singleQuizById: {},
      singleQuizByUser: {},
      loading: false,
      addQuiz: async (values) => {
        try {
          set({ loading: true });

          attachTokenWithFormAxios();
          const response = await formAxios.post("/quiz/create", values);

          set({ loading: false });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      getQuizs: async (values, search) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.post("/quiz/", values);

          set({ loading: false, quiz: response.data?.data?.quizes });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      getSingleQuizs: async (values) => {
        set({ singleQuiz: values });
      },
      getSingleQuizById: async (id) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.get(`/quiz/${id}`);

          set({ loading: false, singleQuizById: response.data?.data?.quiz });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      submitSingleQuizById: async (id, value) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.post(`/quiz/${id}/submit`, value);

          set({ loading: false, singleQuizById: response.data?.data?.quiz });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      editSingleQuizById: async (id, value) => {
        try {
          set({ loading: true });

          attachTokenWithFormAxios();
          const response = await formAxios.put(`/quiz/${id}`, value);

          set({ loading: false });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      deleteSingleQuizById: async (id) => {
        try {
          set({ loading: true });
          attachToken();
          const response = await custAxios.delete(`/quiz/${id}`);
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      getSingleQuizParticipantsById: async (id) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.get(`/quiz/${id}/participants`);

          set({
            loading: false,
            quizParticipants: response.data?.data?.participants,
          });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      getSingleQuizByUser: async (id, userid) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.get(`/quiz/${id}/${userid}/by-user`);

          set({
            loading: false,
            singleQuizByUser: response.data?.data?.submission,
          });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      checkSingleQuiz: async (id, values) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.post(`/quiz/${id}/check`, {
            answers: values,
          });

          set({ loading: false });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
      getSingleQuizLeaderboard: async (id) => {
        try {
          set({ loading: true });

          attachToken();
          const response = await custAxios.get(`/quiz/${id}/leaderboard`);

          set({
            loading: false,
            leaderboard: response.data?.data?.submissions,
          });
          return response.data;
        } catch (error) {
          set({ loading: false });
          console.error("Error fetching data:", error);
        }
      },
    }),
    {
      name: "QuizStore",
      store: "quizStore",
    }
  )
);
