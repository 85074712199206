import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { ModalsProvider } from "@mantine/modals";
import "@mantine/core/styles.css";
import "@mantine/core/styles.layer.css";
import "mantine-datatable/styles.layer.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import React from "react";

// Debug console interference
// const debugConsole = () => {
//   const methods = ["log", "error", "warn", "info"];
//   methods.forEach((method) => {
//     const original = (console as any)[method];
//     (console as any)[method] = (...args: any[]) => {
//       if (original) {
//         original.apply(console, args);
//       } else {
//         // Fallback if original method was lost
//         alert(
//           `Console.${method} was overwritten! Args: ${JSON.stringify(args)}`
//         );
//       }
//     };
//   });
// };

// debugConsole();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // react 18
  // <React.StrictMode>
  <MantineProvider>
    <Notifications position="top-right" zIndex={10000} />
    <ModalsProvider>
      <App />
    </ModalsProvider>
  </MantineProvider>
  // </React.StrictMode>
);
