/**
 * Extracts the YouTube video ID from various YouTube URL formats
 * Supports the following formats:
 * - youtube.com/watch?v=VIDEO_ID
 * - youtu.be/VIDEO_ID
 * - youtube.com/v/VIDEO_ID
 * - youtube.com/embed/VIDEO_ID
 * 
 * @param url YouTube URL string
 * @returns YouTube video ID or undefined if not found
 */
export const extractYoutubeVideoId = (url?: string): string | undefined => {
  if (!url) return undefined;
  
  // Handle youtu.be URLs
  if (url.includes('youtu.be/')) {
    // Format: youtu.be/VIDEO_ID
    const id = url.split('youtu.be/')[1]?.split(/[?&]/)[0];
    return id;
  }
  
  // Handle youtube.com URLs
  if (url.includes('youtube.com')) {
    // Format: youtube.com/watch?v=VIDEO_ID
    if (url.includes('watch?v=')) {
      const id = url.split('watch?v=')[1]?.split(/[?&]/)[0];
      return id;
    }
    
    // Format: youtube.com/embed/VIDEO_ID
    if (url.includes('/embed/')) {
      const id = url.split('/embed/')[1]?.split(/[?&]/)[0];
      return id;
    }
    
    // Format: youtube.com/v/VIDEO_ID
    if (url.includes('/v/')) {
      const id = url.split('/v/')[1]?.split(/[?&]/)[0];
      return id;
    }
  }
  
  return undefined;
};
