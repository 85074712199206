import React, { ReactNode, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import { Loader } from "@mantine/core";
import ChatBox from "../components/aiChat/chatBox";

const DefaultLayout: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [showLoader, setShowLoader] = useState(false);

  // useEffect(() => {
  //   setShowLoader(true);

  //   const timeoutId = setTimeout(() => {
  //     setShowLoader(false);
  //   }, 3000);

  //   // Clean up the timeout in case the component unmounts before the timeout completes
  //   return () => clearTimeout(timeoutId);
  // }, [location.pathname]);
  const handleFloatBtn = () => {
    if (location.pathname !== "/user") {
      navigate("/user");
    } else {
      navigate(-1);
    }
  };
  return (
    <div>
      <div className="w-100" style={{ position: "relative" }}>
        <div className="float-btn" onClick={handleFloatBtn}>
          {location.pathname !== "/user" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={"40px"}
              height={"40px"}
              viewBox="0 0 512 512"
            >
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zm-141.651-35.33c4.937-32.999-20.191-50.739-54.55-62.573l11.146-44.702-27.213-6.781-10.851 43.524c-7.154-1.783-14.502-3.464-21.803-5.13l10.929-43.81-27.198-6.781-11.153 44.686c-5.922-1.349-11.735-2.682-17.377-4.084l.031-.14-37.53-9.37-7.239 29.062s20.191 4.627 19.765 4.913c11.022 2.751 13.014 10.044 12.68 15.825l-12.696 50.925c.76.194 1.744.473 2.829.907-.907-.225-1.876-.473-2.876-.713l-17.796 71.338c-1.349 3.348-4.767 8.37-12.471 6.464.271.395-19.78-4.937-19.78-4.937l-13.51 31.147 35.414 8.827c6.588 1.651 13.045 3.379 19.4 5.006l-11.262 45.213 27.182 6.781 11.153-44.733a1038.209 1038.209 0 0021.687 5.627l-11.115 44.523 27.213 6.781 11.262-45.128c46.404 8.781 81.299 5.239 95.986-36.727 11.836-33.79-.589-53.281-25.004-65.991 17.78-4.098 31.174-15.792 34.747-39.949zm-62.177 87.179c-8.41 33.79-65.308 15.523-83.755 10.943l14.944-59.899c18.446 4.603 77.6 13.717 68.811 48.956zm8.417-87.667c-7.673 30.736-55.031 15.12-70.393 11.292l13.548-54.327c15.363 3.828 64.836 10.973 56.845 43.035z"></path>
            </svg>
          ) : (
            <svg
              width={"40px"}
              height={"40px"}
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 512 512"
              viewBox="0 0 512 512"
            >
              <g>
                <circle cx="256" cy="256" r="253.44"></circle>
                <path
                  fill="transparent"
                  d="M350.019 144.066l17.521 17.522c6.047 6.047 6.047 15.852 0 21.9L183.607 367.419c-6.047 6.048-15.852 6.047-21.9 0l-17.521-17.522c-6.047-6.047-6.047-15.852 0-21.9l183.932-183.933c6.048-6.046 15.853-6.046 21.901.002z"
                ></path>
                <path
                  fill="transparent"
                  d="M367.54 349.899l-17.522 17.522c-6.047 6.047-15.852 6.047-21.9 0L144.186 183.488c-6.047-6.047-6.047-15.852 0-21.9l17.522-17.522c6.047-6.047 15.852-6.047 21.9 0L367.54 327.999c6.048 6.048 6.048 15.853 0 21.9z"
                ></path>
              </g>
            </svg>
          )}
        </div>
        {/* <div className="cryptohopper-web-widget h-25" data-id="2"></div> */}
        <Navbar />

        <Outlet />
        <Footer />

        {/* <ChatBox /> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
