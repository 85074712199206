import { Divider, Menu, Switch } from "@mantine/core";
import React, { useEffect, useState } from "react";
import profile from "../../assets/profile.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../zustand/store/UserStore";
import { baseURL } from "../../configs/axiosConfig";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { getUserData } from "../../services/hooks";
import { motion } from "framer-motion";
import { notifications } from "@mantine/notifications";
interface DashboardNavbar {
  icon: any;
}

const DashboardNavbar: React.FC<DashboardNavbar> = ({ icon }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = getUserData();
  const [userdata, getUserDataApi, loading] = useUserStore(
    useShallow((state) => [state.user, state.userData, state.loading])
  );
  const [editBoolean, setEditBoolean] = useState(false);
  const [logoutFunc] = useAuthStore(useShallow((state) => [state.logoutFunc]));

  useEffect(() => {
    getUserDataApi();
  }, []);

  const handleLogout = async () => {
    try {
      const res = await logoutFunc();

      if (res) {
        // spanish message
        notifications.show({
          title: "Cerrado de sesión",
          message: "Has cerrado sesión correctamente.",
          color: "green",
        });
        navigate("/");
      } else {
        notifications.show({
          title: "Error al cerrar sesión",
          message: res || "Ocurrió un error inesperado al cerrar sesión.",
          color: "red",
        });
      }
    } catch (error) {
      notifications.show({
        title: "Error al cerrar sesión",
        message: "Ocurrió un error inesperado al cerrar sesión.",
        color: "red",
      });
      console.error("Logout failed:", error);
    }
  };

  if (location.pathname?.split("/")[3]?.split("-").length === 2) {
    var routeName = location.pathname?.split("/")[3]?.split("-");
  } else if (location.pathname?.split("/")[2]) {
    var routeName = location.pathname?.split("/")[2]?.split("-");
  } else {
    var routeName = location.pathname?.split("/")[1]?.split("-");
  }

  const handleEditWebsite = () => {
    setEditBoolean(!editBoolean);
  };
  useEffect(() => {
    if (editBoolean === true) {
      localStorage.setItem("edit", "true");
      setEditBoolean(true);
      navigate("/");
    } else {
      localStorage.removeItem("edit");
    }
  }, [editBoolean]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="dashboard-navbar d-flex justify-content-between align-items-center px-4"
    >
      <div className="d-flex align-items-center">
        <span className="buger-btn">{icon}</span>
        <p className="page-heading ms-2 mb-0">
          {routeName.length > 1
            ? `${routeName[0]} ${routeName[1]}`
            : `${routeName[0]}`}
        </p>
      </div>
      <div>
        <Menu trigger="hover">
          <Menu.Target>
            <div className="cursor-pointer d-flex align-items-center">
              {/* <p className="mb-0 me-3 student-name">JOHN DOE</p> */}
              <img
                width={"50px"}
                height={"50px"}
                className="profile-pic"
                src={
                  userdata?.profilePic
                    ? baseURL + userdata?.profilePic
                    : profile
                }
                alt="img"
              />
              <svg
                className="ms-2"
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="6"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  fill="#000"
                  fillRule="evenodd"
                  d="M.292.305a1 1 0 011.413 0L4.994 3.59 8.283.305a.999.999 0 111.412 1.41L5.7 5.709a1 1 0 01-1.412 0L.292 1.716a.998.998 0 010-1.411z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </Menu.Target>
          {/* ... menu items */}
          <Menu.Dropdown>
            <div className=" p-2 pe-4 pb-0 d-flex align-items-center">
              <img
                width={40}
                height={40}
                className="profile-pic"
                src={
                  userdata?.profilePic
                    ? baseURL + userdata?.profilePic
                    : profile
                }
                alt="img"
              />
              <div className="ms-3">
                <p className="mb-0 user-name">{userdata?.name}</p>
                <p className="mb-0 user-email">{userdata?.email}</p>
              </div>
            </div>
            <Divider color="#e8ecef" my="sm" />

            <Menu.Item>
              <Link
                to={"/dashboard/setting"}
                className="w-100 d-flex align-items-center text-decoration-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="15"
                  fill="none"
                  viewBox="0 0 16 15"
                >
                  <path
                    fill="#202020"
                    d="M14.473 5.291c-1.448 0-2.04-1.017-1.32-2.265a1.502 1.502 0 00-.56-2.057L11.21.182a1.34 1.34 0 00-1.823.477L9.3.809c-.72 1.248-1.903 1.248-2.63 0L6.58.66A1.317 1.317 0 004.774.182L3.39.969a1.511 1.511 0 00-.56 2.065c.728 1.24.137 2.257-1.31 2.257-.832 0-1.52.675-1.52 1.51v1.398c0 .827.68 1.51 1.52 1.51 1.447 0 2.038 1.017 1.31 2.264a1.502 1.502 0 00.56 2.058l1.384.787a1.34 1.34 0 001.823-.477l.088-.15c.72-1.248 1.903-1.248 2.63 0l.088.15a1.34 1.34 0 001.823.477l1.384-.787a1.504 1.504 0 00.56-2.058c-.728-1.247-.136-2.264 1.31-2.264.832 0 1.52-.675 1.52-1.51V6.801a1.531 1.531 0 00-1.527-1.51zm-6.477 4.791A2.596 2.596 0 015.397 7.5a2.596 2.596 0 012.599-2.582A2.596 2.596 0 0110.595 7.5a2.596 2.596 0 01-2.599 2.582z"
                  ></path>
                </svg>
                <p className="ms-4 mb-0 text-decoration-none dropdown-link">
                  Ajustes de perfil
                </p>
              </Link>
            </Menu.Item>
            {user?.role === "admin" && (
              <Menu.Item onClick={handleEditWebsite}>
                <p className="w-100 mb-0 text-decoration-none d-flex  align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    fill="none"
                    viewBox="0 0 17 17"
                  >
                    <path
                      fill="#212121"
                      d="M6.264 4.998l-.432-1.056L5.4 4.998h.864z"
                    ></path>
                    <path
                      fill="#212121"
                      d="M2.292 0A2.292 2.292 0 000 2.292v10.416A2.292 2.292 0 002.292 15h5.226a5.391 5.391 0 01-.851-2.917A5.4 5.4 0 017.082 10h-4.79a.625.625 0 010-1.25h5.522a5.407 5.407 0 014.27-2.083c1.073 0 2.074.312 2.916.851V2.292A2.292 2.292 0 0012.708 0H2.292zm2.916 12.917H2.292a.625.625 0 010-1.25h2.916a.625.625 0 110 1.25zM6.412 2.053L8.285 6.64a.625.625 0 01-1.157.472l-.353-.863H4.89l-.353.864a.625.625 0 01-1.157-.474l1.875-4.585a.625.625 0 011.157 0zm4.838.236v1.042h1.042a.625.625 0 110 1.25H11.25v1.042a.625.625 0 01-1.25 0V4.58H8.958a.625.625 0 010-1.25H10V2.29a.625.625 0 111.25 0z"
                    ></path>
                    <path
                      fill="#212121"
                      d="M16.667 12.083a4.583 4.583 0 10-9.167 0 4.583 4.583 0 009.167 0zM12.5 12.5v2.086a.417.417 0 11-.832 0V12.5H9.58a.417.417 0 010-.833h2.087V9.583a.417.417 0 11.833 0v2.084h2.086a.417.417 0 110 .833H12.5z"
                    ></path>
                  </svg>
                  <p className="mx-4 mb-0 dropdown-link">Editar sitio web</p>
                </p>
              </Menu.Item>
            )}
            <Menu.Item onClick={handleLogout}>
              <Link
                to={"/"}
                className="w-100 text-decoration-none d-flex  align-items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="16"
                  fill="none"
                  viewBox="0 0 15 16"
                >
                  <path
                    fill="#202020"
                    fillRule="evenodd"
                    d="M12.632 0c.628 0 1.23.253 1.674.703.445.45.694 1.06.694 1.697v11.2c0 .636-.25 1.247-.694 1.697a2.353 2.353 0 01-1.674.703H7.895c-.628 0-1.23-.253-1.675-.703a2.416 2.416 0 01-.694-1.697V2.4c0-.637.25-1.247.694-1.697A2.353 2.353 0 017.895 0h4.737zM4.505 4.234a.784.784 0 00-1.116 0l-3.158 3.2a.805.805 0 000 1.132l3.158 3.2a.784.784 0 001.107-.01.806.806 0 00.01-1.122L2.695 8.8h6.778c.209 0 .41-.084.558-.234a.806.806 0 000-1.132.784.784 0 00-.558-.234H2.695l1.81-1.834a.805.805 0 000-1.132z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <p className="ms-4 mb-0 dropdown-link">Desconectar</p>
              </Link>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </motion.div>
  );
};

export default DashboardNavbar;
