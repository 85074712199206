import React, { useEffect } from "react";
import logo from "../../assets/logo.png";
import profile from "../../assets/profile.png";
import Register from "../auth/Register";
import GeneralModal from "../modal/GeneralModal";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import Login from "../auth/Login";
import ForgotPassword from "../auth/ForgotPassword";
import ResetPassword from "../auth/ResetPassword";

import { getToken, getUserData, useAuth } from "../../services/hooks";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Burger, Divider, Image, Menu, Avatar } from "@mantine/core";

import { useShallow } from "zustand/react/shallow";
import { useUserStore } from "../../zustand/store/UserStore";
import { baseURL } from "../../configs/axiosConfig";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { TradingViewTickerTape } from "../tables/CryptoCurrencyTable";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { motion } from "framer-motion";

const Navbar: React.FC = () => {
  const token = getToken();
  const user = getUserData();
  const navigate = useNavigate();
  const search = useLocation().search;
  const asToken = new URLSearchParams(search).get("resetToken");

  const [setting, settingGet] = useSettingStore(
    useShallow((state) => [state.setting, state.settingGet])
  );

  const [userdata, getUserDataApi, loading] = useUserStore(
    useShallow((state) => [state.user, state.userData, state.loading])
  );
  const [logoutFunc] = useAuthStore(useShallow((state) => [state.logoutFunc]));

  const [opened, { open, close }] = useDisclosure();
  const [
    openedNavbar,
    { open: openNavbar, close: closeNavbar, toggle: toggleNavbar },
  ] = useDisclosure();
  const { currentForm, switchForm } = useAuth();

  useEffect(() => {
    getUserDataApi();
  }, []);

  const handleLogout = async () => {
    const res = await logoutFunc();

    if (res === "success") {
      navigate("/");
    }
  };

  const handleMenuBtn = () => {
    toggleNavbar();
  };

  // const { currentForm } = useAuth();

  const handleModalClose = () => {
    switchForm("login");
    close();
  };

  useEffect(() => {
    settingGet();
  }, []);

  useEffect(() => {
    close();
  }, [token]);

  useEffect(() => {
    if (asToken) {
      switchForm("resetPassword");
      open();
    }
  }, [asToken]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <TradingViewTickerTape />
      {/* <!-- TradingView Widget END --> */}

      <nav className="navbar navbar-expand-lg  navbar-box">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <Image
              radius="md"
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={70}
              h={70}
              alt="logo"
              className="logo-home"
              fallbackSrc={logo}
            />
          </Link>
          <div className="d-flex align-items-center">
            <button
              className="navbar-toggler me-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <Burger
                color="#fff"
                opened={openedNavbar}
                onClick={handleMenuBtn}
              />
            </button>
            {token && (
              <div className="menu-profile">
                <Menu trigger="hover">
                  <Menu.Target>
                    <div className="cursor-pointer d-flex align-items-center">
                      <Avatar
                        className="profile-pic"
                        w={40}
                        h={40}
                        src={
                          userdata?.profilePic && baseURL + userdata?.profilePic
                        }
                        alt="img"
                      />
                      <svg
                        className="ms-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          fill="#000"
                          fillRule="evenodd"
                          d="M.292.305a1 1 0 011.413 0L4.994 3.59 8.283.305a.999.999 0 111.412 1.41L5.7 5.709a1 1 0 01-1.412 0L.292 1.716a.998.998 0 010-1.411z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </Menu.Target>
                  {/* ... menu items */}
                  <Menu.Dropdown>
                    <div className="p-2 pe-4 pb-0 d-flex align-items-center">
                      <Avatar
                        w={40}
                        h={40}
                        className="profile-pic"
                        src={
                          userdata?.profilePic && baseURL + userdata?.profilePic
                        }
                        alt="img"
                      />
                      <div className="ms-3">
                        <p className="mb-0 user-name">{userdata?.name}</p>
                        <p className="mb-0 user-email">{userdata?.email}</p>
                      </div>
                    </div>
                    <Divider color="#e8ecef" my="sm" />
                    {user?.role === "admin" && (
                      <Menu.Item>
                        <Link
                          to={"/dashboard"}
                          className="w-100 text-decoration-none d-flex  align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="none"
                            viewBox="0 0 22 22"
                          >
                            <path
                              fill="#000"
                              d="M1.222 12.222h7.334c.672 0 1.222-.55 1.222-1.222V1.222C9.778.55 9.228 0 8.556 0H1.222C.55 0 0 .55 0 1.222V11c0 .672.55 1.222 1.222 1.222zm0 9.778h7.334c.672 0 1.222-.55 1.222-1.222v-4.89c0-.671-.55-1.221-1.222-1.221H1.222c-.672 0-1.222.55-1.222 1.222v4.889C0 21.45.55 22 1.222 22zm12.222 0h7.334C21.45 22 22 21.45 22 20.778V11c0-.672-.55-1.222-1.222-1.222h-7.334c-.672 0-1.222.55-1.222 1.222v9.778c0 .672.55 1.222 1.222 1.222zM12.222 1.222v4.89c0 .671.55 1.221 1.222 1.221h7.334c.672 0 1.222-.55 1.222-1.222V1.222C22 .55 21.45 0 20.778 0h-7.334c-.672 0-1.222.55-1.222 1.222z"
                            ></path>
                          </svg>
                          <p className="ms-4 mb-0 dropdown-link">Panel</p>
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item>
                      <Link
                        to={"/profile-setting"}
                        className="w-100 d-flex align-items-center text-decoration-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="15"
                          fill="none"
                          viewBox="0 0 16 15"
                        >
                          <path
                            fill="#202020"
                            d="M14.473 5.291c-1.448 0-2.04-1.017-1.32-2.265a1.502 1.502 0 00-.56-2.057L11.21.182a1.34 1.34 0 00-1.823.477L9.3.809c-.72 1.248-1.903 1.248-2.63 0L6.58.66A1.317 1.317 0 004.774.182L3.39.969a1.511 1.511 0 00-.56 2.065c.728 1.24.137 2.257-1.31 2.257-.832 0-1.52.675-1.52 1.51v1.398c0 .827.68 1.51 1.52 1.51 1.447 0 2.038 1.017 1.31 2.264a1.502 1.502 0 00.56 2.058l1.384.787a1.34 1.34 0 001.823-.477l.088-.15c.72-1.248 1.903-1.248 2.63 0l.088.15a1.34 1.34 0 001.823.477l1.384-.787a1.504 1.504 0 00.56-2.058c-.728-1.247-.136-2.264 1.31-2.264.832 0 1.52-.675 1.52-1.51V6.801a1.531 1.531 0 00-1.527-1.51zm-6.477 4.791A2.596 2.596 0 015.397 7.5a2.596 2.596 0 012.599-2.582A2.596 2.596 0 0110.595 7.5a2.596 2.596 0 01-2.599 2.582z"
                          ></path>
                        </svg>
                        <p className="ms-4 mb-0 text-decoration-none dropdown-link">
                          Ajustes de perfil
                        </p>
                      </Link>
                    </Menu.Item>
                    {user?.role !== "admin" && (
                      <Menu.Item>
                        <Link
                          to={"/my-quiz"}
                          className="w-100 text-decoration-none d-flex  align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            fill="none"
                            viewBox="0 0 17 17"
                          >
                            <path
                              fill="#212121"
                              d="M6.264 4.998l-.432-1.056L5.4 4.998h.864z"
                            ></path>
                            <path
                              fill="#212121"
                              d="M2.292 0A2.292 2.292 0 000 2.292v10.416A2.292 2.292 0 002.292 15h5.226a5.391 5.391 0 01-.851-2.917A5.4 5.4 0 017.082 10h-4.79a.625.625 0 010-1.25h5.522a5.407 5.407 0 014.27-2.083c1.073 0 2.074.312 2.916.851V2.292A2.292 2.292 0 0012.708 0H2.292zm2.916 12.917H2.292a.625.625 0 010-1.25h2.916a.625.625 0 110 1.25zM6.412 2.053L8.285 6.64a.625.625 0 01-1.157.472l-.353-.863H4.89l-.353.864a.625.625 0 01-1.157-.474l1.875-4.585a.625.625 0 011.157 0zm4.838.236v1.042h1.042a.625.625 0 110 1.25H11.25v1.042a.625.625 0 01-1.25 0V4.58H8.958a.625.625 0 010-1.25H10V2.29a.625.625 0 111.25 0z"
                            ></path>
                            <path
                              fill="#212121"
                              d="M16.667 12.083a4.583 4.583 0 10-9.167 0 4.583 4.583 0 009.167 0zM12.5 12.5v2.086a.417.417 0 11-.832 0V12.5H9.58a.417.417 0 010-.833h2.087V9.583a.417.417 0 11.833 0v2.084h2.086a.417.417 0 110 .833H12.5z"
                            ></path>
                          </svg>
                          <p className="ms-4 mb-0 dropdown-link">
                            Mi cuestionario{" "}
                          </p>
                        </Link>
                      </Menu.Item>
                    )}
                    {user?.role !== "admin" && (
                      <Menu.Item>
                        <Link
                          to={"/order-history"}
                          className="w-100 text-decoration-none d-flex  align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="2 0 24 24"
                          >
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 14.915V18h-2v-1.08c-2.339-.367-3-2.002-3-2.92h2c.011.143.159 1 2 1 1.38 0 2-.585 2-1 0-.324 0-1-2-1-3.48 0-4-1.88-4-3 0-1.288 1.029-2.584 3-2.915V6.012h2v1.109c1.734.41 2.4 1.853 2.4 2.879h-1l-1 .018C13.386 9.638 13.185 9 12 9c-1.299 0-2 .516-2 1 0 .374 0 1 2 1 3.48 0 4 1.88 4 3 0 1.288-1.029 2.584-3 2.915z"></path>
                          </svg>
                          <p className="ms-4 mb-0 dropdown-link">
                            Transaction history{" "}
                          </p>
                        </Link>
                      </Menu.Item>
                    )}
                    <Menu.Item onClick={handleLogout}>
                      <Link
                        to={"/"}
                        className="w-100 text-decoration-none d-flex  align-items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="16"
                          fill="none"
                          viewBox="0 0 15 16"
                        >
                          <path
                            fill="#202020"
                            fillRule="evenodd"
                            d="M12.632 0c.628 0 1.23.253 1.674.703.445.45.694 1.06.694 1.697v11.2c0 .636-.25 1.247-.694 1.697a2.353 2.353 0 01-1.674.703H7.895c-.628 0-1.23-.253-1.675-.703a2.416 2.416 0 01-.694-1.697V2.4c0-.637.25-1.247.694-1.697A2.353 2.353 0 017.895 0h4.737zM4.505 4.234a.784.784 0 00-1.116 0l-3.158 3.2a.805.805 0 000 1.132l3.158 3.2a.784.784 0 001.107-.01.806.806 0 00.01-1.122L2.695 8.8h6.778c.209 0 .41-.084.558-.234a.806.806 0 000-1.132.784.784 0 00-.558-.234H2.695l1.81-1.834a.805.805 0 000-1.132z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <p className="ms-4 mb-0 dropdown-link">Desconectar</p>
                      </Link>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            )}
          </div>
          <div
            className={`collapse navbar-collapse collapse-box ${
              openedNavbar && "show"
            }`}
            id="navbarText"
          >
            <span className="navbar-text me-auto"></span>
            <ul className="navbar-nav  mb-2 mb-lg-0 d-flex align-items-center justify-content-start">
              <li className="nav-item">
                <HashLink
                  smooth
                  to="/#about-us"
                  className="nav-link  active"
                  aria-current="page"
                >
                  <p className="mb-0 about-link">acerca de</p>
                </HashLink>
              </li>
              {user && (
                <li className="nav-item">
                  <Link to={"/usdt-exchange"} className="nav-link active">
                    <p className="mb-0 about-link">cambio de usdt</p>
                  </Link>
                </li>
              )}
              {/* <li className="nav-item">
                <Link to={"/leader-board"} className="nav-link ">
                  <button className="leader-board-btn px-3 py-2 ">
                    tabla de líderes
                  </button>
                </Link>
              </li> */}

              <li className="nav-item profile-menu">
                {/* do not remove below commit lines */}
                {token ? (
                  <Menu trigger="hover">
                    <Menu.Target>
                      <div className="cursor-pointer d-flex align-items-center">
                        <p className="mb-0 me-3 student-name-navbar text-uppercase">
                          {userdata?.name}
                        </p>
                        <Avatar
                          className="profile-pic"
                          w={50}
                          h={50}
                          src={
                            userdata?.profilePic &&
                            baseURL + userdata?.profilePic
                          }
                          alt="img"
                        />
                        <svg
                          className="ms-2"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="6"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            fill="#000"
                            fillRule="evenodd"
                            d="M.292.305a1 1 0 011.413 0L4.994 3.59 8.283.305a.999.999 0 111.412 1.41L5.7 5.709a1 1 0 01-1.412 0L.292 1.716a.998.998 0 010-1.411z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <div className=" p-2 pe-4 pb-0 d-flex align-items-center">
                        <Avatar
                          w={40}
                          h={40}
                          className="profile-pic"
                          src={
                            userdata?.profilePic &&
                            baseURL + userdata?.profilePic
                          }
                          alt="img"
                        />
                        <div className="ms-3">
                          <p className="mb-0 user-name">{userdata?.name}</p>
                          <p className="mb-0 user-email">{userdata?.email}</p>
                        </div>
                      </div>
                      <Divider color="#e8ecef" my="sm" />
                      {user?.role === "admin" && (
                        <Menu.Item>
                          <Link
                            to={"/dashboard"}
                            className="w-100 text-decoration-none d-flex  align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 22 22"
                            >
                              <path
                                fill="#000"
                                d="M1.222 12.222h7.334c.672 0 1.222-.55 1.222-1.222V1.222C9.778.55 9.228 0 8.556 0H1.222C.55 0 0 .55 0 1.222V11c0 .672.55 1.222 1.222 1.222zm0 9.778h7.334c.672 0 1.222-.55 1.222-1.222v-4.89c0-.671-.55-1.221-1.222-1.221H1.222c-.672 0-1.222.55-1.222 1.222v4.889C0 21.45.55 22 1.222 22zm12.222 0h7.334C21.45 22 22 21.45 22 20.778V11c0-.672-.55-1.222-1.222-1.222h-7.334c-.672 0-1.222.55-1.222 1.222v9.778c0 .672.55 1.222 1.222 1.222zM12.222 1.222v4.89c0 .671.55 1.221 1.222 1.221h7.334c.672 0 1.222-.55 1.222-1.222V1.222C22 .55 21.45 0 20.778 0h-7.334c-.672 0-1.222.55-1.222 1.222z"
                              ></path>
                            </svg>
                            <p className="ms-4 mb-0 dropdown-link">Panel</p>
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        <Link
                          to={"/profile-setting"}
                          className="w-100 d-flex align-items-center text-decoration-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="15"
                            fill="none"
                            viewBox="0 0 16 15"
                          >
                            <path
                              fill="#202020"
                              d="M14.473 5.291c-1.448 0-2.04-1.017-1.32-2.265a1.502 1.502 0 00-.56-2.057L11.21.182a1.34 1.34 0 00-1.823.477L9.3.809c-.72 1.248-1.903 1.248-2.63 0L6.58.66A1.317 1.317 0 004.774.182L3.39.969a1.511 1.511 0 00-.56 2.065c.728 1.24.137 2.257-1.31 2.257-.832 0-1.52.675-1.52 1.51v1.398c0 .827.68 1.51 1.52 1.51 1.447 0 2.038 1.017 1.31 2.264a1.502 1.502 0 00.56 2.058l1.384.787a1.34 1.34 0 001.823-.477l.088-.15c.72-1.248 1.903-1.248 2.63 0l.088.15a1.34 1.34 0 001.823.477l1.384-.787a1.504 1.504 0 00.56-2.058c-.728-1.247-.136-2.264 1.31-2.264.832 0 1.52-.675 1.52-1.51V6.801a1.531 1.531 0 00-1.527-1.51zm-6.477 4.791A2.596 2.596 0 015.397 7.5a2.596 2.596 0 012.599-2.582A2.596 2.596 0 0110.595 7.5a2.596 2.596 0 01-2.599 2.582z"
                            ></path>
                          </svg>
                          <p className="ms-4 mb-0 text-decoration-none dropdown-link">
                            Ajustes de perfil
                          </p>
                        </Link>
                      </Menu.Item>
                      {user?.role !== "admin" && (
                        <Menu.Item>
                          <Link
                            to={"/my-quiz"}
                            className="w-100 text-decoration-none d-flex  align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="17"
                              fill="none"
                              viewBox="0 0 17 17"
                            >
                              <path
                                fill="#212121"
                                d="M6.264 4.998l-.432-1.056L5.4 4.998h.864z"
                              ></path>
                              <path
                                fill="#212121"
                                d="M2.292 0A2.292 2.292 0 000 2.292v10.416A2.292 2.292 0 002.292 15h5.226a5.391 5.391 0 01-.851-2.917A5.4 5.4 0 017.082 10h-4.79a.625.625 0 010-1.25h5.522a5.407 5.407 0 014.27-2.083c1.073 0 2.074.312 2.916.851V2.292A2.292 2.292 0 0012.708 0H2.292zm2.916 12.917H2.292a.625.625 0 010-1.25h2.916a.625.625 0 110 1.25zM6.412 2.053L8.285 6.64a.625.625 0 01-1.157.472l-.353-.863H4.89l-.353.864a.625.625 0 01-1.157-.474l1.875-4.585a.625.625 0 011.157 0zm4.838.236v1.042h1.042a.625.625 0 110 1.25H11.25v1.042a.625.625 0 01-1.25 0V4.58H8.958a.625.625 0 010-1.25H10V2.29a.625.625 0 111.25 0z"
                              ></path>
                              <path
                                fill="#212121"
                                d="M16.667 12.083a4.583 4.583 0 10-9.167 0 4.583 4.583 0 009.167 0zM12.5 12.5v2.086a.417.417 0 11-.832 0V12.5H9.58a.417.417 0 010-.833h2.087V9.583a.417.417 0 11.833 0v2.084h2.086a.417.417 0 110 .833H12.5z"
                              ></path>
                            </svg>
                            <p className="ms-4 mb-0 dropdown-link">
                              Mi cuestionario{" "}
                            </p>
                          </Link>
                        </Menu.Item>
                      )}
                      {user?.role !== "admin" && (
                        <Menu.Item>
                          <Link
                            to={"/order-history"}
                            className="w-100 text-decoration-none d-flex  align-items-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="2 0 24 24"
                            >
                              <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm1 14.915V18h-2v-1.08c-2.339-.367-3-2.002-3-2.92h2c.011.143.159 1 2 1 1.38 0 2-.585 2-1 0-.324 0-1-2-1-3.48 0-4-1.88-4-3 0-1.288 1.029-2.584 3-2.915V6.012h2v1.109c1.734.41 2.4 1.853 2.4 2.879h-1l-1 .018C13.386 9.638 13.185 9 12 9c-1.299 0-2 .516-2 1 0 .374 0 1 2 1 3.48 0 4 1.88 4 3 0 1.288-1.029 2.584-3 2.915z"></path>
                            </svg>
                            <p className="ms-4 mb-0 dropdown-link">
                              Transaction history{" "}
                            </p>
                          </Link>
                        </Menu.Item>
                      )}

                      <Menu.Item onClick={handleLogout}>
                        <Link
                          to={"/"}
                          className="w-100 text-decoration-none d-flex  align-items-center"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="16"
                            fill="none"
                            viewBox="0 0 15 16"
                          >
                            <path
                              fill="#202020"
                              fillRule="evenodd"
                              d="M12.632 0c.628 0 1.23.253 1.674.703.445.45.694 1.06.694 1.697v11.2c0 .636-.25 1.247-.694 1.697a2.353 2.353 0 01-1.674.703H7.895c-.628 0-1.23-.253-1.675-.703a2.416 2.416 0 01-.694-1.697V2.4c0-.637.25-1.247.694-1.697A2.353 2.353 0 017.895 0h4.737zM4.505 4.234a.784.784 0 00-1.116 0l-3.158 3.2a.805.805 0 000 1.132l3.158 3.2a.784.784 0 001.107-.01.806.806 0 00.01-1.122L2.695 8.8h6.778c.209 0 .41-.084.558-.234a.806.806 0 000-1.132.784.784 0 00-.558-.234H2.695l1.81-1.834a.805.805 0 000-1.132z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <p className="ms-4 mb-0 dropdown-link">Desconectar</p>
                        </Link>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                ) : (
                  <div className="nav-link">
                    <button
                      className="get-started-btn px-3 py-2"
                      onClick={open}
                    >
                      Empezar
                    </button>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <GeneralModal
        opened={opened}
        loading={true}
        Component={
          currentForm === "login"
            ? Login
            : currentForm === "signup"
              ? Register
              : currentForm === "forgotPassword"
                ? ForgotPassword
                : currentForm === "resetPassword"
                  ? ResetPassword
                  : Login
        }
        openModal={open}
        closeModal={handleModalClose}
        size={"80%"}
      />
    </motion.div>
  );
};

export default Navbar;
