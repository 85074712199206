import { create } from "zustand";
import { devtools } from "zustand/middleware";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../configs/axiosConfig";

interface IUserStore {
  user: any;
  singleUserQuizes: any;
  loading: boolean;
  userData: () => Promise<any>;
  updateUserData: (value: any) => Promise<any>;
  quizesOfSingleUser: (
    id: any,
    sortBy?: string | null,
    searchBy?: string | null
  ) => Promise<any>;
  quizesOfLoginUser: (
    sortBy?: string | null,
    searchBy?: string | null
  ) => Promise<any>;
  //   loginFunc: (values: any) => Promise<any>;
}

export const useUserStore = create<IUserStore>()(
  devtools(
    (set) => ({
      user: {},

      singleUserQuizes: [],
      loading: false,
      userData: async () => {
        try {
          set(
            {
              loading: true,
            },
            undefined,
            "user/userData"
          );

          attachToken();
          const response = await custAxios.get("/user/");

          set(
            {
              loading: false,
              user: response?.data?.data?.user,
            },
            undefined,
            "user/userData"
          );
          return response.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "user/userData"
          );
          console.error("Error fetching data:", error);
        }
      },
      updateUserData: async (value) => {
        try {
          set(
            {
              loading: true,
            },
            undefined,
            "user/updateUserData"
          );

          attachTokenWithFormAxios();
          const response = await formAxios.put("/user/", value);

          set(
            {
              loading: false,
              user: response?.data?.data?.user,
            },
            undefined,
            "user/updateUserData"
          );
          return response.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "user/updateUserData"
          );
          console.error("Error fetching data:", error);
        }
      },
      quizesOfSingleUser: async (id, sortBy, searchBy) => {
        try {
          set({
            loading: true,
          });

          attachToken();
          const response = await custAxios.post(
            `user/quizzes-of-single-user/${id}`,
            sortBy
              ? { sortFilter: sortBy }
              : searchBy
                ? { search: searchBy }
                : sortBy && searchBy
                  ? { sortFilter: sortBy, search: searchBy }
                  : {}
          );

          set(
            {
              loading: false,
              singleUserQuizes: response?.data?.data?.leaderboard,
            },
            undefined,
            "user/quizesOfSingleUser"
          );
          return response.data;
        } catch (error) {
          set({
            loading: false,
          });
          console.error("Error fetching data:", error);
        }
      },
      quizesOfLoginUser: async (searchBy) => {
        try {
          set(
            {
              loading: true,
            },
            undefined,
            "user/quizesOfLoginUser"
          );

          attachToken();
          const response = await custAxios.post(`user/quizzes`, {
            // sortFilter: sortBy,
            searchFilter: searchBy,
          });

          set(
            {
              loading: false,
              singleUserQuizes: response?.data?.data?.leaderboard,
            },
            undefined,
            "user/quizesOfLoginUser"
          );
          return response.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "user/quizesOfLoginUser"
          );
          console.error("Error fetching data:", error);
        }
      },
    }),
    {
      name: "UserStore",
      store: "userStore",
    }
  )
);
