import React, { useState, useEffect } from "react";
import { Image, Loader, PasswordInput, TextInput, Box } from "@mantine/core";
import logo from "../../assets/logo.png";
import login from "../../assets/login.png";
import { useAuth } from "../../services/hooks";
import { useAuthStore } from "../../zustand/store/AuthStore";
import { useShallow } from "zustand/react/shallow";
import { useForm } from "@mantine/form";
import { Link, useNavigate } from "react-router-dom";
import { useUserStore } from "../../zustand/store/UserStore";
import { motion } from "framer-motion";
import { baseURL } from "../../configs/axiosConfig";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

const Login: React.FC = () => {
  const { switchForm } = useAuth();
  const navigate = useNavigate();

  const [getUserDataApi] = useUserStore(
    useShallow((state) => [state.userData])
  );
  const [setting, settingGet] = useSettingStore(
    useShallow((state) => [state.setting, state.settingGet])
  );

  const { loginFunc, loading, error } = useAuthStore(
    useShallow((state) => ({
      loginFunc: state.loginFunc,
      loading: state.loading,
      error: state.error,
    }))
  );

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formError, setFormError] = useState<string | null>(null);

  const form = useForm({
    initialValues: { email: "", password: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (!value ? "You must have 8 characters" : null),
    },
  });

  // Clear error state when inputs change
  useEffect(() => {
    setFormError(null);
  }, [email, password]);

  // Clear form and errors when component unmounts
  useEffect(() => {
    return () => {
      form.reset();
      setFormError(null);
    };
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const data = {
        email: email.toLowerCase(),
        password: password,
      };
      const res: any = await loginFunc(data);

      if (res?.data?.success === true) {
        getUserDataApi();
        form.reset();
        setEmail("");
        setPassword("");
        if (res?.data?.userData?.role === "user") {
          navigate("/");
        } else {
          navigate("/dashboard");
        }
      } else if (res?.data?.success === false) {
        // setFormError(res?.data?.message || "Login failed. Please try again.");
      }
    } catch (err) {
      console.error("Login Component Error:", err);
      setFormError("An error occurred. Please try again.");
    }
  };

  // Handle password visibility toggle separately
  const togglePasswordVisibility = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent event from bubbling up
    console.log("togglePasswordVisibility");
    setShowPassword((prev) => !prev);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="signup-conatiner "
    >
      <div className="row ">
        <div className="col-md-12 col-lg-6">
          <div className="login-img-box ">
            <Image
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={100}
              h={100}
              alt="logo"
              fallbackSrc={logo}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <form onSubmit={handleSubmit}>
            <p className="mt-5 register-title text-uppercase mb-1">
              Bienvenido <span>a Your Way Community</span>
            </p>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Correo electrónico</p>
                <TextInput
                  size="md"
                  {...form.getInputProps("email")}
                  value={email}
                  onChange={(event) => {
                    setEmail(event.currentTarget.value);
                    setFormError(null);
                  }}
                  disabled={loading}
                  error={formError}
                />
              </label>
            </div>
            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Contraseña</p>
                <PasswordInput
                  size="md"
                  {...form.getInputProps("password")}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.currentTarget.value);
                    setFormError(null);
                  }}
                  disabled={loading}
                  error={formError}
                  type={showPassword ? "text" : "password"}
                />
              </label>
            </div>
            <p className="checkout-text text-end">
              <span
                onClick={() => switchForm("forgotPassword")}
                className="cursor-pointer"
                style={{ zIndex: 5, position: "relative" }}
              >
                Forgot Password
              </span>
            </p>
            <button
              type="submit"
              disabled={loading ? true : false}
              className="py-2 my-3 create-account text-uppercase"
              style={{ position: "relative", zIndex: 5 }}
            >
              {loading ? (
                <Loader size={"sm"} color="white" />
              ) : (
                "INICIAR SESIÓN"
              )}
            </button>
            <p className="checkout-text text-center my-3 mb-3 pb-1 ">
              No tienes una cuenta{" "}
              <span
                className="cursor-pointer"
                onClick={() => {
                  console.log("switchForm");
                  switchForm("signup");
                }}
                style={{ zIndex: 5, position: "relative" }}
              >
                {" "}
                Crea tu cuenta
              </span>
            </p>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;
