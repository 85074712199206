import { create } from "zustand";
import { devtools } from "zustand/middleware";
import custAxios, {
  attachToken,
  attachTokenWithFormAxios,
  formAxios,
} from "../../configs/axiosConfig";

interface IUserStore {
  setting: any;
  settingImg: any;

  loading: boolean;
  settingAddAndEdit: (value: any) => Promise<any>;
  settingGet: () => Promise<any>;
  settingImage: (value: any) => Promise<any>;

  //   loginFunc: (values: any) => Promise<any>;
}

export const useSettingStore = create<IUserStore>()(
  devtools(
    (set) => ({
      setting: {},
      settingImg: null,
      loading: false,
      settingAddAndEdit: async (value) => {
        try {
          set({
            loading: true,
          });

          attachToken();
          const response = await custAxios.post("/settings/", value);

          set(
            {
              loading: false,
              // setting: response?.data?.data?.settings.content,
            },
            undefined,
            "setting/settingAddAndEdit"
          );

          return response?.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "setting/settingAddAndEdit"
          );
          console.error("Error fetching data:", error);
        }
      },
      settingGet: async () => {
        try {
          set({
            loading: true,
          });

          attachToken();
          const response = await custAxios.get("/settings/");

          set({
            loading: false,
            setting: response?.data?.data?.settings.content,
          });

          return response.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "setting/settingGet"
          );
          console.error("Error fetching data:", error);
        }
      },
      settingImage: async (value) => {
        try {
          set(
            {
              loading: true,
            },
            undefined,
            "setting/settingImage"
          );

          attachTokenWithFormAxios();
          const response = await formAxios.post(
            "/settings/image-uploader",
            value
          );

          set(
            {
              loading: false,
              settingImg: response?.data?.data?.image,
            },
            undefined,
            "setting/settingImage"
          );

          return response?.data;
        } catch (error) {
          set(
            {
              loading: false,
            },
            undefined,
            "setting/settingImage"
          );
          console.error("Error fetching data:", error);
        }
      },
    }),
    {
      name: "SettingStore",
      store: "settingStore",
    }
  )
);
