import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import { useQuizStore } from "../../zustand/store/QuizStore";
import { useTranscriptStore } from "../../zustand/store/TranscriptStore";
import { Loader } from "@mantine/core";
import { UseGetRole } from "../../services/hooks";
import YouTube from "react-youtube";
import { motion } from "framer-motion";
import { extractYoutubeVideoId } from "../../utils/youtubeUtils";

interface IQuizData {
  createdAt: string;
  endDate: string;
  answers?: any;
  rank?: number;
  score?: number;
  user?: any;
  quiz?: any;
  question: {
    key: string;
    que: string;
    _id: string;
  }[];
  startDate: string;
  thumbnail: string;
  title: string;
  updatedAt: string;
  video: string;
  __v: number;
  _id: string;
}
declare global {
  interface Window {
    YT: {
      PlayerState: {
        UNSTARTED: 0;
        ENDED: 1;
        PLAYING: 2;
        PAUSED: 3;
        BUFFERING: 4;
        CUED: 5;
      };
    };
  }
}
interface QuizVideoSectionInterface {
  data: IQuizData;
}
const QuizVideoSection: React.FC<QuizVideoSectionInterface> = (data) => {
  const location = useLocation();
  const role = UseGetRole();
  const [quizArray, singleQuiz, getSingleQuiz] = useQuizStore(
    useShallow((state: any) => [
      state.quiz,
      state.singleQuiz,
      state.getSingleQuizs,
    ])
  );
  data = data || quizArray[quizArray.length - 1];

  const [intervalId, setIntervalId] = useState<any>(null);
  const [videoId, setVideoId] = useState<string>();
  const [videoId2, setVideoId2] = useState<string>();
  const [transcript, getTransript, loading] = useTranscriptStore(
    useShallow((state: any) => [
      state.transcript,
      state.getTranscript,
      state.loading,
    ])
  );

  useEffect(() => {
    if (singleQuiz?.video) {
      // Extract video ID using the utility function
      const videoID =
        extractYoutubeVideoId(singleQuiz.video) ||
        extractYoutubeVideoId(data?.data?.video);

      if (videoID) {
        setVideoId(videoID);

        const res = getTransript({
          videoId: videoID,
          lang: "es",
        });
      }
    } else {
      const videoID = extractYoutubeVideoId(data?.data?.video);
      console.log("Extracted Video ID:", videoID);

      if (videoID) {
        setVideoId(videoID);
        const res = getTransript({
          videoId: videoID,
          lang: "es",
        });
      }
    }
  }, [singleQuiz, data]);

  useEffect(() => {
    if (data?.data?.quiz?.video) {
      const videoID2 = extractYoutubeVideoId(data?.data?.quiz?.video);
      if (videoID2) {
        setVideoId2(videoID2);
        const res = getTransript({
          videoId: videoID2,
          lang: "es",
        });
      }
    } else {
      const videoID2 = extractYoutubeVideoId(data?.data?.video);
      if (videoID2) {
        setVideoId2(videoID2);
        const res = getTransript({
          videoId: videoID2,
          lang: "es",
        });
      }
    }
  }, [data]);

  const [player, setPlayer] = useState<any>(null);
  const [currentTime, setCurrentTime] = useState<any>(0);

  // Ref for the YouTube player
  const playerRef = useRef(null);

  // Options for the YouTube player
  const opts = {
    height: "420px",
    width: "100%",
    playerVars: {
      // Add any additional player parameters here
      autoplay: 1,
      showinfo: 0,
    },
  };

  const onReady = (event: any) => {
    setPlayer(event.target);
  };

  const getYoutubeVideoPlayback = () => {
    setCurrentTime(player.getCurrentTime() || 0);
  };
  // Function to handle changes in the video's current time
  const onPlayerStateChange = (event: any) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      // Update the current time regularly while the video is playing
      const id = setInterval(getYoutubeVideoPlayback, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
    }
  };
  const highlightedTextRef = useRef<any>(null);

  useEffect(() => {
    const containerElement: any = document.querySelector(".paragraph-box");

    // Assuming you have a reference to the highlighted text element
    const highlightedTextElement: any =
      document.querySelector(".highlighted-text");

    // Check if both elements exist before attempting to scroll
    if (containerElement && highlightedTextElement) {
      // Calculate the offset based on the viewport width
      const offset =
        window.innerWidth < 991 ? 1000 : window.innerWidth < 768 ? 900 : 700;

      // Scroll to the top of the container element with an adjusted offset
      containerElement.scrollTop = highlightedTextElement.offsetTop - offset;
    }
  }, [currentTime]);

  const selectedVideoId =
    location?.pathname === "/quiz-hub" ? videoId : videoId2;

  console.log("quizVideoSection", {
    videoId,
    videoId2,
    selectedVideoId,
    data,
  });

  return (
    <div className="video-container my-5">
      <div className="black-box ">
        <div className="container">
          <div className="row align-items-center pb-5">
            <motion.div
              initial={{ opacity: 0, x: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="col-md-12 col-lg-6 "
            >
              <YouTube
                videoId={selectedVideoId} // Replace with your actual YouTube video ID
                opts={opts}
                onReady={onReady}
                onStateChange={onPlayerStateChange}
                ref={playerRef}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="col-md-12 col-lg-6"
            >
              <div className="">
                {/* <div className="three-dots d-flex justify-content-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="12"
                    fill="none"
                    viewBox="0 0 54 12"
                  >
                    <circle cx="6" cy="6" r="6" fill="#DBDDE9"></circle>
                    <circle cx="27" cy="6" r="6" fill="#DBDDE9"></circle>
                    <circle cx="48" cy="6" r="6" fill="#DBDDE9"></circle>
                  </svg>
                </div> */}
                <div>
                  {loading ? (
                    <div className="d-flex justify-content-center">
                      <Loader color="white" />
                    </div>
                  ) : (
                    <div>
                      {transcript?.length > 0 ? (
                        <div className="paragraph-box">
                          {transcript?.map((value: any, i: number) => {
                            const endtime = value?.duration + value?.start;

                            return (
                              <p
                                key={i}
                                ref={
                                  currentTime > value?.start
                                    ? highlightedTextRef
                                    : null
                                }
                                className={
                                  currentTime > value?.start &&
                                  currentTime < endtime
                                    ? "ps-2 highlighted-text video-paragraph mb-4"
                                    : "video-paragraph mb-4"
                                }
                              >
                                {value?.text}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        <div className="h-100 mt-5 pt-5 d-flex justify-content-center align-items-center">
                          <div className="result-not-found">
                            <p className="not-found-text text-white mb-0">
                              No hay transcripción disponible
                            </p>
                            <p className="not-found-paragraph text-white">
                              ¡No hay transcripción disponible en este video!
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
            {role !== "admin" && (
              <div>
                {location.pathname !== "/quiz-details" && (
                  <Link
                    to={`/start-quiz/${
                      singleQuiz?._id ? singleQuiz?._id : data?.data?._id
                    }`}
                  >
                    <button
                      type="button"
                      className="get-started mt-5 px-4 py-2 text-uppercase"
                    >
                      iniciar prueba
                    </button>
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizVideoSection;
