import { create } from "zustand";
import custAxios from "../../configs/axiosConfig";
import { notifications } from "@mantine/notifications";
import { errorMessage, successMessage } from "../../services/helpers";

interface IAuthStore {
  user: any;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  register: (values: any, set?: (value: object) => void) => Promise<any>;
  loginFunc: (values: any) => Promise<any>;
  logoutFunc: () => Promise<any>;
  forgotPasswordFunc: (values: any) => Promise<any>;
  resetPasswordFunc: (values: any) => Promise<any>;
  checkAuthStatus: () => void;
}

const registerFunc = async (values: any, set: (value: object) => void) => {
  try {
    set({
      loading: true,
      error: null,
    });
    const response = await custAxios.post("/auth/register", values);

    set({
      loading: false,
    });
    notifications.show({
      title: "Su cuenta se registró exitosamente",
      message: "Ahora puede iniciar sesión con su cuenta registrada",
      autoClose: 5000,
      color: "yellow",
    });
    return response.data;
  } catch (error: any) {
    const errorMsg = error?.response?.data?.message || "Registration failed";
    notifications.show({
      title: "Registro fallido",
      message: errorMsg,
      autoClose: 5000,
      color: "red",
    });
    set({
      loading: false,
      error: errorMsg,
    });
    console.error("Error fetching data:", error);
    return { success: false, message: errorMsg };
  }
};

export const useAuthStore = create<IAuthStore>((set, get) => {
  // Initialize auth state from localStorage
  const initializeAuthState = () => {
    try {
      const userData = localStorage.getItem("user");
      const token = localStorage.getItem("token");

      if (token && userData) {
        const parsedUserData = JSON.parse(userData);
        return {
          user: parsedUserData,
          isAuthenticated: true,
          token: token,
        };
      }
      return {
        user: null,
        isAuthenticated: false,
        token: null,
      };
    } catch (error) {
      console.error("Error initializing auth state:", error);
      // Clear potentially corrupted data
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return {
        user: null,
        isAuthenticated: false,
        token: null,
      };
    }
  };

  const initialState = initializeAuthState();

  return {
    user: initialState.user,
    isAuthenticated: initialState.isAuthenticated,
    loading: false,
    error: null,

    checkAuthStatus: () => {
      const state = initializeAuthState();
      set({
        user: state.user,
        isAuthenticated: state.isAuthenticated,
      });
    },

    register: (values) => registerFunc(values, set),

    loginFunc: async (values) => {
      try {
        set({
          loading: true,
          error: null,
        });
        const response = await custAxios.post("/auth/login", values);

        if (response?.data?.success) {
          const userData = response?.data?.data?.userData;
          const token = response?.data?.data?.token;

          // Store user data and token in localStorage
          localStorage.setItem("user", JSON.stringify(userData));
          localStorage.setItem("token", token);

          set({
            user: userData,
            loading: false,
            isAuthenticated: true,
            error: null,
          });

          return response.data;
        } else {
          throw new Error(response?.data?.message || "Login failed");
        }
      } catch (error: any) {
        const errorMsg =
          error?.response?.data?.message ||
          error.message ||
          "Invalid credentials";

        notifications.show({
          title: "Credenciales no válidas",
          message: errorMsg,
          autoClose: 5000,
          color: "red",
        });

        set({
          loading: false,
          isAuthenticated: false,
          error: errorMsg,
        });

        return { success: false, message: errorMsg };
      }
    },

    forgotPasswordFunc: async (values) => {
      try {
        set({
          loading: true,
          error: null,
        });
        const res = await custAxios.post("/auth/forgotPassword", values);
        if (res?.data?.success) {
          set({
            loading: false,
          });
          successMessage(res?.data?.data);
        }
        return res.data;
      } catch (error: any) {
        const errorMsg =
          error?.response?.data?.message || "Failed to process request";
        set({
          loading: false,
          error: errorMsg,
        });
        errorMessage(errorMsg);
        return { success: false, message: errorMsg };
      }
    },

    resetPasswordFunc: async (values) => {
      try {
        set({
          loading: true,
          error: null,
        });
        const res = await custAxios.put("/auth/resetPassword", values);
        if (res?.data?.success) {
          set({
            loading: false,
            error: null,
          });
          successMessage(res?.data?.data);
        }
        return res.data;
      } catch (error: any) {
        const errorMsg =
          error?.response?.data?.message || "Failed to reset password";
        set({
          loading: false,
          error: errorMsg,
        });
        errorMessage(errorMsg);
        return { success: false, message: errorMsg };
      }
    },

    logoutFunc: async () => {
      try {
        set({
          loading: true,
        });

        // Call logout API
        await custAxios.get("/auth/logout").catch((err) => {
          console.warn(
            "Logout API call failed, but proceeding with local logout",
            err
          );
        });

        // Always clear local storage and state, even if API call fails
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        set({
          user: null,
          loading: false,
          isAuthenticated: false,
          error: null,
        });

        return { success: true, message: "Logged out successfully" };
      } catch (error) {
        console.error("Error during logout:", error);

        // Still clear local storage and state on error
        localStorage.removeItem("user");
        localStorage.removeItem("token");

        set({
          user: null,
          loading: false,
          isAuthenticated: false,
          error: null,
        });

        return { success: true, message: "Logged out successfully" };
      }
    },
  };
});
