import React, { useState } from "react";
import { pageContentData } from "../../data/data";
import { useLocation } from "react-router-dom";
import { UseGetRole } from "../../services/hooks";
import { Loader, Modal, Textarea, rem } from "@mantine/core";
import GeneralModal from "../modal/GeneralModal";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useShallow } from "zustand/react/shallow";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { notifications } from "@mantine/notifications";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto, IconUpload, IconX } from "@tabler/icons-react";

interface EditButtonProps {
  dataKey: string;
  pageName: string; // Adjust the type based on your actual data type
  inputType: string; // Adjust the type based on your actual data type
  colour?: string; // Adjust the type based on your actual data type
}

const EditContent: React.FC = ({
  content,
  contentKey,
  saveFunc,
  setting,
}: any) => {
  const [contentValue, setContentValue] = useState<string>(content);

  // alert(contentValue);
  // const [setting, settingAddAndEdit, loading] = useSettingStore(
  //   useShallow((state) => [
  //     state.setting,
  //     state.settingAddAndEdit,
  //     state.loading,
  //   ])
  // );

  // const handleSaveSetting = () => {
  //   settingAddAndEdit("kjlkk");
  // };

  return (
    <div className=" m-2">
      <p>Edit Content</p>
      <div className="edit-text-box">
        <Textarea
          placeholder="Enter Content"
          autosize
          minRows={10}
          value={contentValue}
          onChange={(e) => setContentValue(e.target.value)}
        />
      </div>
      <div className="mt-3 d-flex justify-content-between align-items-center">
        <button className="px-3 py-1 black-btn">Cancel</button>
        <button
          onClick={() => saveFunc(contentValue)}
          className="px-3 py-1 yellow-btn"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

const EditButton: React.FC<EditButtonProps> = ({
  dataKey,
  pageName,
  inputType,
  colour,
}) => {
  const location = useLocation();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const editable = localStorage.getItem("edit");
  const role = UseGetRole();
  const [opened, { open, close }] = useDisclosure();
  // console.log(editable, role, pageContentData);
  const [thumbnailImg, setThumbnailImg] = useState();
  const [thumbnailImgURL, setThumbnailImgURL] = useState<string>();
  const [settingImgUrl, setSettingImgUrl] = useState<string>();

  const [contentValue, setContentValue] = useState<string>();
  const [contentEdit, setContentEdit] = useState("");
  const [updateSetting, setUpdateSetting] = useState<any>({});

  const [setting, settingAddAndEdit, settingGet, settingImage, loading] =
    useSettingStore(
      useShallow((state) => [
        state.setting,
        state.settingAddAndEdit,
        state.settingGet,
        state.settingImage,
        state.loading,
      ])
    );

  const handleThumbnailImg = async (file: any) => {
    setThumbnailImg(file);
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      const res = await settingImage(formData);
      if (res?.success) {
        setSettingImgUrl(res?.data?.image);
      }
      const newImageUrl = URL.createObjectURL(file);
      setThumbnailImgURL(newImageUrl);
    }
  };

  const handleFindContent = () => {
    if (pageName === "home") {
      const foundValue = setting?.find((value: any) => value?.home[dataKey]);
      if (foundValue) {
        setContentEdit(foundValue?.home[dataKey]);
        open();
      }
    } else if (pageName === "footer") {
      const foundValue = setting?.find((value: any) => value?.footer[dataKey]);
      if (foundValue) {
        setContentEdit(foundValue?.footer[dataKey]);
        open();
      }
    } else if (pageName === "leaderBoard") {
      const foundValue = setting?.find(
        (value: any) => value?.leaderBoard[dataKey]
      );
      if (foundValue) {
        setContentEdit(foundValue?.leaderBoard[dataKey]);
        open();
      }
    } else if (pageName === "usdt_exchange") {
      const foundValue = setting?.find(
        (value: any) => value?.usdt_exchange[dataKey]
      );
      if (foundValue) {
        setContentEdit(foundValue?.usdt_exchange[dataKey]);
        open();
      }
    }
  };

  // const handleSaveSetting = ({ value }: any) => {
  //   console.log(value);

  //   if (location.pathname == "/") {
  //     const foundValue = pageContentData?.find(
  //       (value: any) => value?.home[dataKey]
  //     );
  //     if (foundValue) {
  //       const updatedPageContentData = [pageContentData]?.map((value: any) => {
  //         console.log(value[0].home);

  //         if (value[0].home[dataKey]) {
  //           const updatedHome = { ...value.home, [dataKey]: value };
  //           return updatedHome;
  //         }
  //         return value;
  //       });

  //       // setContentEdit(foundValue.home[dataKey]);
  //       console.log(updateSetting, foundValue.home[dataKey]);
  //       open();
  //       // alert(foundValue);

  //       //  setContentModal(foundValue?.home[dataKey]);
  //       //  setShow(true);
  //       settingAddAndEdit({ content: updatedPageContentData });
  //     }
  //   }
  // };

  const handleSaveSetting = async (value: any) => {
    if (pageName === "home") {
      const foundValue = setting?.find((item: any) => item?.home[dataKey]);

      if (foundValue) {
        const updatedPageContentData = setting?.map((item: any) => {
          if (item.home[dataKey]) {
            return {
              ...item,
              home: {
                ...item.home,
                [dataKey]: inputType === "text" ? value : settingImgUrl,
              },
            };
          }
          return item;
        });

        open();
        const res = await settingAddAndEdit({
          content: updatedPageContentData,
        });

        if (res?.success === true) {
          const res2 = await settingGet();
          if (res2.success) {
            notifications.show({
              title: "Content updated",
              message: "Your content updated successfully",
              autoClose: 5000,
              color: "yellow",
            });
            close();
          }
        } else {
          notifications.show({
            title: "Content update failed",
            message: "Your content didn't updated successfully",
            autoClose: 5000,
            color: "red",
          });
        }
      }
    } else if (pageName === "footer") {
      const foundValue = setting?.find((value: any) => value?.footer[dataKey]);
      if (foundValue) {
        const updatedPageContentData = setting?.map((item: any) => {
          if (item.footer[dataKey]) {
            return {
              ...item,
              footer: {
                ...item.footer,
                [dataKey]: inputType === "text" ? value : settingImgUrl,
              },
            };
          }
          return item;
        });

        open();
        const res = await settingAddAndEdit({
          content: updatedPageContentData,
        });
        if (res.success) {
          const res2 = await settingGet();

          if (res2.success) {
            notifications.show({
              title: "Content updated",
              message: "Your content updated successfully",
              autoClose: 5000,
              color: "yellow",
            });
            close();
          }
        } else {
          notifications.show({
            title: "Content update failed",
            message: "Your content didn't updated successfully",
            autoClose: 5000,
            color: "red",
          });
        }
      }
    } else if (pageName === "leaderBoard") {
      const foundValue = setting?.find(
        (value: any) => value?.leaderBoard[dataKey]
      );
      if (foundValue) {
        const updatedPageContentData = setting?.map((item: any) => {
          if (item.leaderBoard[dataKey]) {
            return {
              ...item,
              leaderBoard: {
                ...item.leaderBoard,
                [dataKey]: inputType === "text" ? value : settingImgUrl,
              },
            };
          }
          return item;
        });

        open();
        const res = await settingAddAndEdit({
          content: updatedPageContentData,
        });
        if (res.success) {
          const res2 = await settingGet();

          if (res2.success) {
            notifications.show({
              title: "Content updated",
              message: "Your content updated successfully",
              autoClose: 5000,
              color: "yellow",
            });
            close();
          }
        } else {
          notifications.show({
            title: "Content update failed",
            message: "Your content didn't updated successfully",
            autoClose: 5000,
            color: "red",
          });
        }
      }
    } else if (pageName === "usdt_exchange") {
      const foundValue = setting?.find(
        (value: any) => value?.usdt_exchange[dataKey]
      );
      if (foundValue) {
        const updatedPageContentData = setting?.map((item: any) => {
          if (item.usdt_exchange[dataKey]) {
            return {
              ...item,
              usdt_exchange: {
                ...item.usdt_exchange,
                [dataKey]: inputType === "text" ? value : settingImgUrl,
              },
            };
          }
          return item;
        });

        open();
        const res = await settingAddAndEdit({
          content: updatedPageContentData,
        });
        if (res.success) {
          const res2 = await settingGet();

          if (res2.success) {
            notifications.show({
              title: "Content updated",
              message: "Your content updated successfully",
              autoClose: 5000,
              color: "yellow",
            });
            close();
          }
        } else {
          notifications.show({
            title: "Content update failed",
            message: "Your content didn't updated successfully",
            autoClose: 5000,
            color: "red",
          });
        }
      }
    }
  };

  return (
    <>
      {" "}
      {editable === "true" && role === "admin" ? (
        <svg
          onClick={() => handleFindContent()}
          style={{
            width: "25px",
            marginLeft: "10px",
            color: "gray",
            cursor: "pointer",
          }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={colour || "#fff"}
          // className=" "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
          />
        </svg>
      ) : null}
      <div className="edit-modal-box">
        <Modal
          opened={opened}
          onClose={close}
          withCloseButton={false}
          size="auto"
          // title="Authentication"
          centered
        >
          <div className=" m-2">
            <p>Contenido editado</p>
            {inputType === "text" ? (
              <div className="edit-text-box">
                <Textarea
                  placeholder="Enter Content"
                  autosize
                  minRows={10}
                  value={contentEdit}
                  onChange={(e) => setContentEdit(e.target.value)}
                />
              </div>
            ) : (
              <div className="edit-text-box">
                <Dropzone
                  onDrop={(files) => handleThumbnailImg(files[0])}
                  onReject={(files) => console.log("rejected files", files)}
                  maxSize={5 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE}
                >
                  <Dropzone.Accept>
                    <IconUpload
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-blue-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <IconX
                      style={{
                        width: rem(52),
                        height: rem(52),
                        color: "var(--mantine-color-red-6)",
                      }}
                      stroke={1.5}
                    />
                  </Dropzone.Reject>
                  {thumbnailImgURL ? (
                    <img
                      className="thumbnail-img"
                      width={"100%"}
                      src={thumbnailImgURL}
                      alt="thumbnail"
                    />
                  ) : (
                    <>
                      <Dropzone.Idle>
                        <div className="d-flex justify-content-center">
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-dimmed)",
                            }}
                            stroke={1.5}
                          />
                        </div>
                      </Dropzone.Idle>

                      <div>
                        <p className="text-center mb-0 thumbnail-heading">
                          Arrastre las imágenes aquí o haga clic para
                          seleccionar archivos
                        </p>

                        <p className="thumbnail-text text-center">
                          Adjunte una imagen en miniatura para su cuestionario;
                          el archivo no debe exceder los 5mb
                        </p>
                      </div>
                    </>
                  )}
                </Dropzone>
              </div>
            )}
            <div className="mt-3 d-flex justify-content-between align-items-center">
              <button
                onClick={() => close()}
                className="px-3 py-1 black-btn me-3"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleSaveSetting(contentEdit)}
                className="px-3 py-1 yellow-btn ms-3"
              >
                {loading ? (
                  <Loader size={"sm"} color="white" />
                ) : (
                  "Guardar cambios"
                )}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EditButton;
