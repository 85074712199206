import React from "react";
import { Loader, PasswordInput, TextInput, Image } from "@mantine/core";
import logo from "../../assets/logo.png";
import { useAuth } from "../../services/hooks";
import { useAuthStore } from "../../zustand/store/AurthStore";
import { useShallow } from "zustand/react/shallow";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../zustand/store/UserStore";
import { motion } from "framer-motion";
import { useSettingStore } from "../../zustand/store/SettingStore";
import { baseURL } from "../../configs/axiosConfig";
import { notifications } from "@mantine/notifications";

const ForgotPassword = () => {
  const { switchForm } = useAuth();
  const navigate = useNavigate();

  const [getUserDataApi] = useUserStore(
    useShallow((state) => [state.userData])
  );

  const [forgotPasswordFunc, loading] = useAuthStore(
    useShallow((state) => [state.forgotPasswordFunc, state.loading])
  );
  const [setting] = useSettingStore(useShallow((state) => [state.setting]));
  const form = useForm({
    initialValues: { email: "" },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (values: { email: string }) => {
    const data = {
      email: values.email.toLowerCase(),
    };

    try {
      const res = await forgotPasswordFunc(data);

      if (res.success) {
        notifications.show({
          title: "Correo enviado",
          message: "Revisa tu correo para restablecer tu contraseña",
          color: "green",
        });

        setTimeout(() => {
          form.reset();
          switchForm("resetPassword");
        }, 2000);
      } else {
        // Assuming the API returns a message on failure.  Adjust as needed.
        notifications.show({
          title: "Error",
          message: res.message || "Hubo un problema al enviar el correo.",
          color: "red",
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Error",
        message: error?.message || "Hubo un problema al enviar el correo.",
        color: "red",
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="signup-conatiner "
    >
      <div className="row ">
        <div className="col-md-12 col-lg-6">
          <div className="login-img-box ">
            <Image
              // radius="md"
              src={baseURL + setting[0]?.websiteSetting?.logo || logo}
              w={100}
              h={100}
              alt="logo"
              fallbackSrc={logo}
            />
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <form onSubmit={form.onSubmit(handleSubmit)}>
            <p className="mt-5 register-title text-uppercase">
              Olvidar contraseña
            </p>

            <p>
              Para restablecer tu contraseña, por favor ingresa la dirección de
              correo electrónico asociada a tu cuenta. Te enviaremos un enlace
              seguro para que puedas crear una nueva contraseña. Asegúrate de
              que sea el correo electrónico vinculado a tu cuenta para un
              proceso de recuperación sin problemas.
            </p>

            <div>
              <label className="form-label mt-2">
                <p className="form-label-text mb-2">Correo electrónico</p>
                <TextInput size="md" {...form.getInputProps("email")} />
              </label>
            </div>

            <button
              disabled={loading ? true : false}
              className="py-2 my-3 create-account text-uppercase"
              type="submit"
            >
              {loading ? <Loader size={"sm"} color="white" /> : "Verificar"}
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default ForgotPassword;
