import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UseGetRole, useAuthentication } from "../services/hooks";
import { useAuthStore } from "../zustand/store/AuthStore";
import { notifications } from "@mantine/notifications";

interface AdminRouteProps {
  Component: React.ComponentType<any>;
}

const AdminRoute: React.FC<AdminRouteProps> = ({
  Component,
}: AdminRouteProps) => {
  const isAuthenticated: boolean = useAuthentication();
  const role: string = UseGetRole();
  const navigate = useNavigate();
  const { checkAuthStatus } = useAuthStore();

  // Verify authentication status on mount
  useEffect(() => {
    checkAuthStatus();
  }, []);

  // Handle different authentication scenarios
  if (!isAuthenticated) {
    // User is not authenticated, redirect to login
    notifications.show({
      title: "Authentication Required",
      message: "Please log in to access the admin panel",
      autoClose: 5000,
      color: "yellow",
    });
    return <Navigate to="/" />;
  } else if (role !== "admin") {
    // User is authenticated but not an admin
    notifications.show({
      title: "Access Denied",
      message: "You don't have permission to access the admin panel",
      autoClose: 5000,
      color: "red",
    });
    return <Navigate to="/not-found" />;
  } else {
    // User is authenticated and is an admin
    return <Component />;
  }
};

export default AdminRoute;
