import { create } from "zustand";
import { devtools } from "zustand/middleware";
import custAxios, { attachToken } from "../../configs/axiosConfig";

interface IUserStore {
  transcript: any;
  loading: boolean;
  getTranscript: (value: any) => Promise<any>;
}

export const useTranscriptStore = create<IUserStore>()(
  devtools(
    (set) => ({
      transcript: [],

      loading: false,

      getTranscript: async (value) => {
        try {
          set(
            {
              loading: true,
            },
            undefined,
            "transcript/getTranscript"
          );

          attachToken();
          const response = await custAxios.post(
            "https://youtube-api-python-production-70b1.up.railway.app/",
            value
          );

          set(
            {
              loading: false,
              transcript: response?.data,
            },
            undefined,
            "transcript/getTranscript"
          );
          return response.data;
        } catch (error) {
          set(
            {
              loading: false,
              transcript: [],
            },
            undefined,
            "transcript/getTranscript"
          );
          console.error("Error fetching data:", error);
        }
      },
    }),
    {
      name: "TranscriptStore",
      store: "transcriptStore",
    }
  )
);
